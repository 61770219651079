import Image from "@components/Image";
import styles from "./TextImage.module.scss";
import Markdown from "@components/Markdown";
import cx from "classnames";
import _ from "lodash";
const View = ({ title, description, image, imagePosition }: any) => {
  return (
    <div className={cx("inner-article", styles.section, styles[imagePosition])}>
      <div className="flex flex-col flex-1">
        <h2 className={cx("ama-title", styles.title)}>{title}</h2>
        <div className={cx("ama-content", styles.content)}>
          <Markdown content={description} />
          {/* {description} */}
        </div>
      </div>
      <div className={cx("flex flex-1", styles.boxImg)}>
        <Image
          wrapperProps={{ className: styles.image }}
          src={_.get(image, "src")}
          alt={_.get(image, "alt")}
        />
      </div>
    </div>
  );
};
export default View;
