import React, { createContext, useContext, ReactNode, useEffect } from "react";
import { AppTranslationContext } from "@type/provider/Translation";
import useRoute from "@hooks/useNextRoute";
import "@translations/i18n";
import { Trans, useTranslation } from "react-i18next";
import _ from "lodash";

export const TranslationContext = createContext<AppTranslationContext>({
  i18n: {
    t: () => {},
  },
  Trans: () => {
    return <></>;
  },
});

interface TypeUseTranslationContext {
  (): AppTranslationContext;
}

const TranslationProvider = ({
  children,
}: AppTranslationContext): ReactNode => {
  const { t, i18n } = useTranslation();
  const router = useRoute();
  const locale: any = _.get(router, "query.locale", "");

  useEffect(() => {
    if (locale && locale !== i18n.language) {
      i18n.changeLanguage(locale || "en");
    }
  }, [locale, i18n]);

  const i18nModel = {
    t: t,
    changeLanguage: i18n.changeLanguage,
    language: i18n.language,
  };

  return (
    <TranslationContext.Provider value={{ i18n: i18nModel, Trans }}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslationContext: TypeUseTranslationContext = () => {
  return useContext(TranslationContext);
};

export default TranslationProvider;
